import { template as template_086244f81ad84b09beea3c420fae67b0 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_086244f81ad84b09beea3c420fae67b0(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
