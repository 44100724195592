import { template as template_fd95a93d1fda46cca3c21ac99bb68afa } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_fd95a93d1fda46cca3c21ac99bb68afa(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
