import { template as template_cbabd20416c34c31900b3acfc9943a8e } from "@ember/template-compiler";
const FKText = template_cbabd20416c34c31900b3acfc9943a8e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
