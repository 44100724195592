import { template as template_cf9a2c97c7194ca2ae42ced3145e4589 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_cf9a2c97c7194ca2ae42ced3145e4589(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
