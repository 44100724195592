import { template as template_bf968d7b5e7b4219978ff1c89a39a2c0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_bf968d7b5e7b4219978ff1c89a39a2c0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
